<template>
  <div class="global-footer">
    <b-container class="footer-container">
      <b-row align-h="center" no-gutters cols="1" cols-sm="4" cols-md="4">
        <b-col><b-link href="services.html" class="footer-link">事業内容</b-link></b-col>
        <b-col><b-link href="cases.html" class="footer-link">施工事例</b-link></b-col>
        <b-col><b-link href="company.html" class="footer-link">会社情報</b-link></b-col>
        <b-col><b-link href="inquiry.html" class="footer-link">お問い合わせ</b-link></b-col>
        <b-col><a href="#" class="footer-link">TOPに戻る</a></b-col>
      </b-row>
      <b-row align-h="center"> Copyright (C) 2017 Shuuji Co, Inc. </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "GlobalFooter",
};
</script>

<style scoped>
.global-footer {
  color: rgba(255, 255, 255, 0.89);
  background-color: #000000;
  margin-top: auto;
}
.footer-link {
  color: rgba(255, 255, 255, 0.89);
}
.footer-container {
  max-width: 500px;
}
@media only screen and (max-width: 420px) {
  .footer-container {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 750px) {
  .footer-container {
    font-size: 1.2rem;
  }
}
</style>
